import React, { useState, useEffect } from "react";
import s from "./Menu.module.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/authSlice";
import {
  FaUserShield,
  FaUser,
  FaInfoCircle,
  FaFileDownload,
  FaBuilding,
  FaBullseye,
  FaPhoneAlt,
  FaUsersCog,
  FaFileAlt,
} from "react-icons/fa";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const Menu = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAboutMenuOpen, setIsAboutMenuOpen] = useState(false); // Подменю "О нас"
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, roles } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    roles: state.auth.roles || [],
  }));

  const handleLogout = async () => {
    try {
      const response = await fetch("/api/logout", {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        dispatch(logout());
        navigate("/login");
      } else {
        console.error("Error during logout");
      }
    } catch (error) {
      console.error("Network error during logout attempt:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const toggleAboutMenu = () => {
    setIsAboutMenuOpen((prevState) => !prevState);
  };

  const isAdmin = roles.includes("ROLE_ADMIN");

  useEffect(() => {
    setIsSidebarOpen(false);
    setIsAboutMenuOpen(false);
  }, [location]);

  return (
      <div className={s.menu_div}>
        {/* Бургер-кнопка и кнопка "Главная" */}
        <div className={s.left_section}>
          <button
              className={`${s.burger_button} ${isSidebarOpen ? s.burger_open : ""}`}
              onClick={toggleSidebar}
          >
            <span className={s.burger_line}></span>
            <span className={s.burger_line}></span>
            <span className={s.burger_line}></span>
          </button>
          <NavLink to="/" className={s.menu_item}>
            Главная
          </NavLink>
        </div>

        {/* Боковая панель */}
        <div className={`${s.sidebar} ${isSidebarOpen ? s.sidebar_open : ""}`}>
          <button className={s.close_sidebar} onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faArrowLeft}/>
          </button>
          <div className={s.sidebar_content}>
            <NavLink to="/library" className={s.sidebar_item}>
              <FaFileAlt /> Библиотека
            </NavLink>
            {!isAuthenticated ? (
                <>
                  <div className={s.sidebar_item} onClick={toggleAboutMenu}>
                    <FaInfoCircle /> О нас
                  </div>
                  {isAboutMenuOpen && (
                      <div className={s.submenu}>
                        <NavLink to="/documents" className={s.sidebar_item}>
                          <FaFileDownload /> Документы
                        </NavLink>
                        <NavLink to="/about/organization" className={s.sidebar_item}>
                          <FaBuilding /> Об организации
                        </NavLink>
                        <NavLink to="/about/mission" className={s.sidebar_item}>
                          <FaBullseye /> Миссия и цели
                        </NavLink>
                        <NavLink to="/about/contacts" className={s.sidebar_item}>
                          <FaPhoneAlt /> Контакты
                        </NavLink>
                        <NavLink to="/about/team" className={s.sidebar_item}>
                          <FaUsersCog /> Наша команда
                        </NavLink>
                      </div>
                  )}
                </>
            ) : (
                <>
                  <NavLink to="/profile" className={s.sidebar_item}>
                    <FaUser /> Личный кабинет
                  </NavLink>
                  {isAdmin && (
                      <NavLink to="/admin" className={s.sidebar_item}>
                        <FaUserShield /> Админка
                      </NavLink>
                  )}
                  <button onClick={handleLogout} className={s.sidebar_item}>
                    Выйти
                  </button>
                </>
            )}
          </div>
        </div>

        {/* Кнопка "Войти/Выйти" */}
        <div className={s.auth_section}>
          {isAuthenticated ? (
              <button onClick={handleLogout} className={s.auth_button}>
                Выйти
              </button>
          ) : (
              <NavLink to="/login" className={s.auth_button}>
                Войти
              </NavLink>
          )}
        </div>
      </div>
  );
};

export default Menu;
