import React from "react";
import styles from "./Events.module.css";

const Event = (props) => {
  return (
    <div className={styles.eventCard}>
      <h3 className={styles.eventName}>{props.eventName}</h3>
      <p className={styles.eventDescription}>{props.description}</p>
    </div>
  );
};

export default Event;
