import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ExpensesPage.module.css';
import commonStyles from '../../common/CommonStyles.module.css';
import { useNavigate } from 'react-router-dom';

const ExpensesPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    fetchExpenses();
  }, []);

  const handleBack = () => {
    navigate('/admin');
  };

  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/expenses');
      setExpenses(response.data);
      setError(null);
    } catch (error) {
      console.error('Ошибка при загрузке расходов:', error);
      setError('Не удалось загрузить список расходов');
    } finally {
      setLoading(false);
    }
  };

  const addExpense = async () => {
    try {
      const data = {
        description,
        amount: parseFloat(amount),
        date
      };

      const response = await axios.post('/api/expenses', data);
      setExpenses([...expenses, response.data]);
      setDescription('');
      setAmount('');
      setDate('');
    } catch (error) {
      console.error('Ошибка при добавлении расхода:', error);
      alert('Произошла ошибка при добавлении расхода');
    }
  };

  const deleteExpense = async (id) => {
    try {
      await axios.delete(`/api/expenses/${id}`);
      setExpenses(expenses.filter(expense => expense.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении расхода:', error);
      alert('Произошла ошибка при удалении расхода');
    }
  };

  if (loading) {
    return (
      <div className={commonStyles.pageContainer}>
        <button onClick={handleBack} className={commonStyles.backButton}>
          ← Вернуться в меню
        </button>
        <div className={commonStyles.contentContainer}>
          <div className={commonStyles.content}>
            <div className={styles.loading}>Загрузка данных...</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={commonStyles.pageContainer}>
      <button onClick={handleBack} className={commonStyles.backButton}>
        ← Вернуться в меню
      </button>

      <div className={commonStyles.contentContainer}>
        <div className={commonStyles.content}>
          <h2 className={styles.title}>Расходы</h2>
          
          {error ? (
            <div className={styles.error}>
              {error}
              <button onClick={fetchExpenses} className={styles.retryButton}>
                Попробовать снова
              </button>
            </div>
          ) : (
            <>
              <section className={styles.addExpenseSection}>
                <h3 className={styles.subtitle}>Добавить расход</h3>
                <div className={styles.formSection}>
                  <label className={styles.label}>Описание:</label>
                  <input
                    type="text"
                    className={styles.input}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Введите описание расхода"
                  />
                </div>

                <div className={styles.formSection}>
                  <label className={styles.label}>Сумма:</label>
                  <input
                    type="number"
                    className={styles.input}
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    placeholder="Введите сумму"
                  />
                </div>

                <div className={styles.formSection}>
                  <label className={styles.label}>Дата:</label>
                  <input
                    type="date"
                    className={styles.input}
                    value={date}
                    onChange={e => setDate(e.target.value)}
                  />
                </div>

                <button className={styles.submitButton} onClick={addExpense}>
                  Добавить расход
                </button>
              </section>

              <section className={styles.expensesSection}>
                <h3 className={styles.subtitle}>Список расходов</h3>
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Описание</th>
                        <th>Сумма</th>
                        <th>Дата</th>
                        <th>Действие</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expenses.map(expense => (
                        <tr key={expense.id}>
                          <td>{expense.description}</td>
                          <td>{expense.amount} руб</td>
                          <td>{new Date(expense.date).toLocaleDateString('ru-RU')}</td>
                          <td>
                            <button
                              className={styles.deleteButton}
                              onClick={() => deleteExpense(expense.id)}
                            >
                              Удалить
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpensesPage;
