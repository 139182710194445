import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Events.module.css";

const AddEvent = (props) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  
  const onSubmit = async (data) => {
    try {
      const rawResponse = await fetch("/api/events", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      
      if (!rawResponse.ok) {
        throw new Error('Ошибка при добавлении события');
      }
      
      const content = await rawResponse.json();
      props.addEvent(content);
      reset(); // Очищаем форму после успешного добавления
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Произошла ошибка при добавлении события');
    }
  };

  return (
    <form className={styles.eventForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formGroup}>
        <input
          type="text"
          className={styles.input}
          placeholder="Название события"
          {...register("eventName", { 
            required: "Это поле обязательно для заполнения",
            minLength: { value: 3, message: "Минимальная длина 3 символа" }
          })}
        />
        {errors.eventName && (
          <span className={styles.errorText}>{errors.eventName.message}</span>
        )}
      </div>
      
      <div className={styles.formGroup}>
        <input
          type="text"
          className={styles.input}
          placeholder="Описание события"
          {...register("description", { 
            required: "Это поле обязательно для заполнения",
            minLength: { value: 10, message: "Минимальная длина 10 символов" }
          })}
        />
        {errors.description && (
          <span className={styles.errorText}>{errors.description.message}</span>
        )}
      </div>

      <div className={styles.formGroup}>
        <input
          type="date"
          className={styles.input}
          {...register("eventDate", { required: "Выберите дату события" })}
        />
        {errors.eventDate && (
          <span className={styles.errorText}>{errors.eventDate.message}</span>
        )}
      </div>
      
      <button type="submit" className={styles.submitButton}>
        Добавить событие
      </button>
    </form>
  );
};

export default AddEvent;
