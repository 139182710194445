import React from "react";
import s from "./AdminPage.module.css";
import { NavLink } from "react-router-dom";

const AdminPage = () => {
  return (
      <div className={s.container}>
        <h2 className={s.title}>Панель администратора</h2>
        <div className={s.menu}>
          <NavLink
              to="/admin/print-docs"
              className={(navData) => (navData.isActive ? s.active : s.item)}
          >
            Печать документов
          </NavLink>
          <NavLink
              to="/admin/events"
              className={(navData) => (navData.isActive ? s.active : s.item)}
          >
            События
          </NavLink>
          <NavLink
              to="/admin/contributions"
              className={(navData) => (navData.isActive ? s.active : s.item)}
          >
            Взносы
          </NavLink>
          <NavLink
              to="/admin/expenses"
              className={(navData) => (navData.isActive ? s.active : s.item)}
          >
            Расходы
          </NavLink>
        </div>
      </div>
  );
};

export default AdminPage;
