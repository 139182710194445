import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './PrintDocsPage.module.css';
import commonStyles from '../../common/CommonStyles.module.css';

const PrintDocument = () => {
  const navigate = useNavigate();
  const { docType, pioneerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await fetch(`/api/print/${docType}/${pioneerId}`);
        if (!response.ok) {
          throw new Error('Ошибка при получении документа');
        }
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        setDocumentUrl(url);
      } catch (error) {
        console.error('Ошибка:', error);
        setError(error.message || 'Произошла ошибка при загрузке документа');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [docType, pioneerId]);

  const handleBack = () => {
    navigate('/admin/print-docs');
  };

  const handlePrint = () => {
    if (documentUrl) {
      window.open(documentUrl, '_blank');
    }
  };

  return (
    <div className={commonStyles.pageContainer}>
      <button onClick={handleBack} className={commonStyles.backButton}>
        ← Вернуться к выбору документа
      </button>

      <div className={commonStyles.contentContainer}>
        <div className={commonStyles.content}>
          <h2 className={styles.title}>
            {docType === 'application' ? 'Заявление' : 'Анкета'}
          </h2>

          {loading && <div className={styles.loading}>Загрузка документа...</div>}
          {error && <div className={styles.error}>{error}</div>}
          
          {documentUrl && (
            <div className={styles.documentActions}>
              <button onClick={handlePrint} className={styles.printButton}>
                Открыть для печати
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintDocument; 