import React from "react";
import { FaSeedling, FaUsers, FaCheckCircle } from "react-icons/fa";
import s from "./MissionSection.module.css";

const MissionSection = () => {
  return (
      <div className={s.missionContainer}>
        <h2 className={s.title}>Наша миссия и цели</h2>
        <p className={s.intro}>
          Ленинский комсомол – это общероссийская общественная молодёжная
          организация, объединяющая юношей и девушек, вдохновлённых идеалами
          справедливости, солидарности и развития. Мы – школьники, студенты,
          молодые специалисты и активисты, которые стремятся сделать мир
          лучше и сохранить лучшие традиции молодёжного движения.
        </p>
        <div className={s.goals}>
          <div className={s.goal}>
            <h3 className={s.goalTitle}>Солидарность</h3>
            <p className={s.goalText}>
              Мы учим молодёжь работать в команде, уважать друг друга и строить
              отношения на принципах взаимопомощи и дружбы, чтобы вместе
              достигать общих целей.
            </p>
            <FaUsers className={s.icon}/> {/* Иконка "Солидарность" */}
          </div>
          <div className={s.goal}>
            <h3 className={s.goalTitle}>Развитие</h3>
            <p className={s.goalText}>
              Наша организация помогает каждому раскрыть свои способности и
              стать лучше. Мы предлагаем возможности для учёбы, творчества,
              культурного и спортивного роста.
            </p>
            <FaSeedling className={s.icon}/> {/* Иконка "Развитие" */}
          </div>
          <div className={s.goal}>
            <h3 className={s.goalTitle}>Ответственность</h3>
            <p className={s.goalText}>
              Мы воспитываем у молодёжи понимание важности их действий,
              уважение к окружающим и стремление сделать вклад в будущее
              общества.
            </p>
            <FaCheckCircle className={s.icon}/> {/* Иконка "Ответственность" */}
          </div>
        </div>
      </div>
  );
};

export default MissionSection;
