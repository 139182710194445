import React from "react";
import s from "./Header.module.css";
import data from "../../../data/Header.json";

const Header = () => {
  const title = data.name;

  return (
      <div className={s.headerContainer}>
        <div className={s.overlay}>
          <h1 className={s.headerH}>{title}</h1>
          <p className={s.headerDescription}>{data.description}</p>
        </div>
      </div>
  );
};

export default Header;
