import "./App.css";
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import MainPage from "./components/mainPage/mainPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegistrationForm from "./components/Registration/RegistrationForm";
import "@fontsource/ysabeau-sc";
import Menu from "./components/Menu/Menu";
import AdminPage from "./components/AdminPage/AdminPage";
import PrintDocs from "./components/AdminPage/PrintDocs";
import LoginPage from "./components/LoginPage/LoginPage";
import SuccessRegistration from "./components/Registration/SuccessRegistration";
import PrivateRoute from "./components/PrivateRoute";
import Library from "./components/Library/Library";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, fetchUserRoles } from "./redux/authSlice";
import EventsContainer from "./components/AdminPage/Events/EventsContainer";
import DocumentsPage from "./components/DocumentsPage/DocumentsPage";
import { Helmet } from "react-helmet-async";
import ContributionsPage from "./components/AdminPage/ContributionsPage/ContributionsPage";
import ExpensesPage from "./components/AdminPage/ExpensesPage/ExpensesPage";
import AdminBooks from "./components/AdminBooks/AdminBooks";
import PrintDocsPage from "./components/AdminPage/PrintDocsPage/PrintDocsPage";
import PrintDocument from "./components/AdminPage/PrintDocsPage/PrintDocument";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const roles = JSON.parse(localStorage.getItem("roles"));
      if (roles) {
        dispatch(setUser({ roles })); // Устанавливаем роли из localStorage
      } else {
        dispatch(fetchUserRoles()); // Запрашиваем роли с сервера
      }
    }
  }, [dispatch, isAuthenticated]);

  return (
      <div className="App">
        <Helmet>
          <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <BrowserRouter>
          <Menu />
          <div className="content-area">
            <Routes>
              <Route
                  path="/admin/books"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                      <AdminBooks />
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/admin/contributions"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                      <ContributionsPage />
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/admin/expenses"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                      <ExpensesPage />
                    </PrivateRoute>
                  }
              />
              <Route path="/library" element={<Library />} />
              <Route path="/" element={<MainPage />} />
              <Route path="/registration" element={<RegistrationForm />} />
              <Route
                  path="/admin"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                      <AdminPage />
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/admin/print-docs"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                      <PrintDocsPage />
                    </PrivateRoute>
                  }
              />
              <Route
                  path="/admin/print-docs/:docType/:pioneerId"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                    <PrintDocument />
                  </PrivateRoute>
                  }
              />
              <Route
                  path="/admin/events"
                  element={
                    <PrivateRoute requiredRole="ROLE_ADMIN">
                    <EventsContainer />
                  </PrivateRoute>
                  }
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route
                  path="/registrationSuccess"
                  element={<SuccessRegistration />}
              />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
  );
}

export default App;
