import React, { useEffect, useState } from "react";
import AddEvent from "./AddEvent";
import GetUserEvents from "./GetUserEvents";
import Event from "./Event";
import styles from "./Events.module.css";
import commonStyles from '../../common/CommonStyles.module.css';
import { useNavigate } from "react-router-dom";

const AllEvents = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/admin');
  };

  const getEvents = async () => {
    try {
      setLoading(true);
      const rawResponse = await fetch(`/api/events`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      if (!rawResponse.ok) {
        throw new Error('Ошибка при получении событий');
      }
      
      const data = await rawResponse.json();
      props.setEvents(data);
    } catch (error) {
      console.error(error);
      setError('Произошла ошибка при загрузке событий');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  let events = props.events.map((item) => (
    <Event key={item.id} eventName={item.eventName} description={item.description} />
  ));

  return (
    <div className={commonStyles.pageContainer}>
      <button onClick={handleBack} className={commonStyles.backButton}>
        ← Вернуться в меню
      </button>

      <div className={commonStyles.contentContainer}>
        <div className={commonStyles.content}>
          <h2 className={styles.title}>События</h2>
          
          {loading && <div className={styles.loading}>Загрузка событий...</div>}
          {error && <div className={styles.error}>{error}</div>}
          
          <div className={styles.eventsContainer}>
            {events}
          </div>
          
          <AddEvent addEvent={props.addEvent} />
          <br />
          <GetUserEvents
            pioneerId={props.pioneerId}
            events={props.events}
            userEvents={props.userEvents}
            setUserEvents={props.setUserEvents}
            setPioneerId={props.setPioneerId}
            addUserEvent={props.addUserEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default AllEvents;
