import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ContributionsPage.module.css';
import commonStyles from '../../common/CommonStyles.module.css';
import { useNavigate } from 'react-router-dom';

const ContributionsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [membersCount, setMembersCount] = useState('');
  const [amount, setAmount] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRegionName, setNewRegionName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [regionStats, setRegionStats] = useState([]);
  const [isRegionView, setIsRegionView] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [contributions, setContributions] = useState([]);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    fetchRegions();
    fetchBalance();
    fetchRegionStats();
  }, []);

  const handleBack = () => {
    navigate('/admin');
  };

  const fetchRegions = async () => {
    try {
      const response = await axios.get('/api/regions');
      setRegions(response.data);
      setError(null);
    } catch (error) {
      console.error('Ошибка при загрузке регионов:', error);
      setError('Не удалось загрузить список регионов');
    } finally {
      setLoading(false);
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await axios.get('/api/contributions/balance');
      setBalance(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке баланса:', error);
    }
  };

  const fetchRegionStats = async () => {
    try {
      const response = await axios.get('/api/regions/stats');
      setRegionStats(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке статистики:', error);
    }
  };

  const addMultipleContributions = async () => {
    try {
      const data = {
        regionId: selectedRegion,
        startMonth: `${startMonth}-01`,
        endMonth: `${endMonth}-01`,
        membersCount: parseInt(membersCount),
        totalAmount: parseFloat(amount)
      };

      await axios.post('/api/contributions/multiple', data);
      fetchRegionStats();
      fetchBalance();
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Ошибка при добавлении взносов');
    }
  };

  const fetchRegionContributions = async (regionId) => {
    try {
      const response = await axios.get(`/api/contributions/region/${regionId}`);
      setContributions(response.data);
      setSelectedRegionId(regionId);
      setIsRegionView(true);
    } catch (error) {
      console.error('Ошибка при загрузке взносов региона:', error);
    }
  };

  const deleteContribution = async (id) => {
    try {
      await axios.delete(`/api/contributions/${id}`);
      setContributions(contributions.filter(c => c.id !== id));
      fetchRegionStats();
      fetchBalance();
    } catch (error) {
      console.error('Ошибка при удалении взноса:', error);
    }
  };

  const addRegion = async () => {
    try {
      const response = await axios.post('/api/regions', { name: newRegionName });
      setRegions([...regions, response.data]);
      setIsModalOpen(false);
      setNewRegionName('');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Ошибка при добавлении региона');
    }
  };

  const goBack = () => {
    setIsRegionView(false);
    setSelectedRegionId(null);
    setContributions([]);
  };

  if (loading) {
    return (
      <div className={commonStyles.pageContainer}>
        <button onClick={handleBack} className={commonStyles.backButton}>
          ← Вернуться в меню
        </button>
        <div className={commonStyles.contentContainer}>
          <div className={commonStyles.content}>
            <div className={styles.loading}>Загрузка данных...</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={commonStyles.pageContainer}>
      <button onClick={handleBack} className={commonStyles.backButton}>
        ← Вернуться в меню
      </button>

      <div className={commonStyles.contentContainer}>
        <div className={commonStyles.content}>
          <h2 className={styles.title}>Взносы</h2>
          
          {error ? (
            <div className={styles.error}>
              {error}
              <button onClick={fetchRegions} className={styles.retryButton}>
                Попробовать снова
              </button>
            </div>
          ) : !isRegionView ? (
            <>
              <p className={styles.balance}><strong>Баланс:</strong> {balance} руб</p>
              <div className={styles.formSection}>
                <label className={styles.label}>Регион:</label>
                <div className={styles.regionSelectContainer}>
                  <select
                    className={styles.select}
                    value={selectedRegion}
                    onChange={e => setSelectedRegion(e.target.value)}
                  >
                    <option value="">Выберите регион</option>
                    {regions.map(region => (
                      <option key={region.id} value={region.id}>{region.name}</option>
                    ))}
                  </select>
                  <button className={styles.addButton} onClick={() => setIsModalOpen(true)}>
                    Добавить регион
                  </button>
                </div>
              </div>

              <div className={styles.formSection}>
                <label className={styles.label}>С месяца:</label>
                <input
                  type="month"
                  className={styles.input}
                  value={startMonth}
                  onChange={e => setStartMonth(e.target.value)}
                />
              </div>

              <div className={styles.formSection}>
                <label className={styles.label}>По месяц:</label>
                <input
                  type="month"
                  className={styles.input}
                  value={endMonth}
                  onChange={e => setEndMonth(e.target.value)}
                />
              </div>

              <div className={styles.formSection}>
                <label className={styles.label}>Количество членов:</label>
                <input
                  type="number"
                  className={styles.input}
                  value={membersCount}
                  onChange={e => setMembersCount(e.target.value)}
                />
              </div>

              <div className={styles.formSection}>
                <label className={styles.label}>Сумма:</label>
                <input
                  type="number"
                  className={styles.input}
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                />
              </div>

              <button className={styles.submitButton} onClick={addMultipleContributions}>
                Добавить взносы
              </button>

              {errorMessage && <p className={styles.error}>{errorMessage}</p>}

              <h3 className={styles.subtitle}>Статистика по регионам</h3>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Регион</th>
                      <th>Общая сумма взносов</th>
                      <th>Последний месяц</th>
                      <th>Количество членов (последний месяц)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regionStats.map(region => (
                      <tr
                        key={region.id}
                        onClick={() => fetchRegionContributions(region.id)}
                        className={styles.tableRow}
                      >
                        <td>{region.name}</td>
                        <td>{region.totalAmount || '0'} руб</td>
                        <td>{region.lastMonth || 'Нет данных'}</td>
                        <td>{region.lastMembersCount || 'Нет данных'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <button className={styles.backButton} onClick={goBack}>
                ← Вернуться к списку регионов
              </button>
              <h3 className={styles.subtitle}>Взносы региона</h3>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Месяц</th>
                      <th>Количество участников</th>
                      <th>Сумма</th>
                      <th>Действие</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contributions.map(contribution => (
                      <tr key={contribution.id}>
                        <td>{contribution.month}</td>
                        <td>{contribution.membersCount}</td>
                        <td>{contribution.amount}</td>
                        <td>
                          <button
                            className={styles.deleteButton}
                            onClick={() => deleteContribution(contribution.id)}
                          >
                            Удалить
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {isModalOpen && (
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <h3>Добавить регион</h3>
                {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                <div className={styles.formSection}>
                  <label className={styles.label}>Название региона:</label>
                  <input
                    type="text"
                    className={styles.input}
                    value={newRegionName}
                    onChange={e => setNewRegionName(e.target.value)}
                  />
                </div>
                <div className={styles.modalActions}>
                  <button className={styles.submitButton} onClick={addRegion}>
                    Добавить
                  </button>
                  <button className={styles.cancelButton} onClick={() => setIsModalOpen(false)}>
                    Отмена
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContributionsPage;
