import React from "react";
import styles from "./Events.module.css";

const UserEvent = (props) => {
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/user-events/${props.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Ошибка при удалении события');
      }

      props.onDelete(props.id);
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Произошла ошибка при удалении события');
    }
  };

  return (
    <div className={styles.userEventItem}>
      <div>
        <span className={styles.userEventName}>{props.eventName}</span>
        <span className={styles.userEventDate}>{formatDate(props.eventDate)}</span>
      </div>
      <button
        className={styles.deleteButton}
        onClick={handleDelete}
        title="Удалить событие"
      >
        Удалить
      </button>
    </div>
  );
};

export default UserEvent;
