import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FooterGallery.css";

const images = [
  "/images/gallery/lksm.jpg",
  "/images/gallery/lksm2.jpg",
  "/images/gallery/lksm3.jpg",
  "/images/gallery/lksm4.jpg",
  "/images/gallery/lksm5.jpg",
  "/images/gallery/lksm6.jpg",
];

const FooterGallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
      <footer className="footer-gallery-container">
        <h1>Галерея</h1>
        <div className="gallery-slider">
          <Slider {...settings}>
            {images.map((src, index) => (
                <div key={index} className="gallery-slide">
                  <img src={src} alt={`Gallery Image ${index + 1}`}
                       className="gallery-image"/>
                </div>
            ))}
          </Slider>
        </div>
        <div className="footer-info">
          <div className="footer-logo">
            <img src="/images/lksm.png" alt="Pioneers Logo"
                 className="footer-logo-img"/>
          </div>
          <p>Кировский комсомол</p>
          <p>2024 ©</p>

        </div>
      </footer>
  );
};

export default FooterGallery;
