import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PrintDocsPage.module.css';
import commonStyles from '../../common/CommonStyles.module.css';

const PrintDocsPage = () => {
  const navigate = useNavigate();
  const [selectedDoc, setSelectedDoc] = useState('');
  const [pioneerId, setPioneerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleBack = () => {
    navigate('/admin');
  };

  const handlePrint = async () => {
    if (!selectedDoc || !pioneerId) {
      setError('Пожалуйста, выберите документ и введите ID пионера');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      // Проверяем существование пионера
      const checkResponse = await fetch(`/api/pioneers/${pioneerId}`);
      if (!checkResponse.ok) {
        throw new Error('Пионер с указанным ID не найден');
      }

      // Перенаправляем на страницу с документом
      navigate(`/admin/print-docs/${selectedDoc}/${pioneerId}`);
    } catch (error) {
      console.error('Ошибка:', error);
      setError(error.message || 'Произошла ошибка при подготовке документа');
    } finally {
      setLoading(false);
    }
  };

  const documents = [
    { id: 'application', name: 'Заявление' },
    { id: 'questionnaire', name: 'Анкета' }
  ];

  return (
    <div className={commonStyles.pageContainer}>
      <button onClick={handleBack} className={commonStyles.backButton}>
        ← Вернуться в меню
      </button>
      
      <div className={commonStyles.contentContainer}>
        <div className={commonStyles.content}>
          <h2 className={styles.title}>Печать документов</h2>

          <div className={styles.printForm}>
            <div className={styles.formSection}>
              <label className={styles.label}>Выберите документ:</label>
              <select
                className={styles.select}
                value={selectedDoc}
                onChange={(e) => setSelectedDoc(e.target.value)}
              >
                <option value="">Выберите тип документа</option>
                {documents.map(doc => (
                  <option key={doc.id} value={doc.id}>
                    {doc.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.formSection}>
              <label className={styles.label}>ID пионера:</label>
              <input
                type="text"
                className={styles.input}
                value={pioneerId}
                onChange={(e) => setPioneerId(e.target.value)}
                placeholder="Введите ID пионера"
              />
            </div>

            {error && (
              <div className={styles.error}>
                {error}
              </div>
            )}

            <button
              className={styles.printButton}
              onClick={handlePrint}
              disabled={loading || !selectedDoc || !pioneerId}
            >
              {loading ? 'Подготовка документа...' : 'Распечатать документ'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintDocsPage; 